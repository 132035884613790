import { nanoid } from 'nanoid';

// WEBSITE Header Meta
export const headData = {
  title: 'Rishi Singh Bhachu | Full Stack Developer | Portfolio', // Website Name
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Portfolio Website for Full Stack Developer Rishi Singh Bhachu', // e.g: Welcome to my website
  // keywords: '' // to add??
  // google tag manager?
};

// HEADER DATA
export const headerData = {
  networks: [
    /*
    {
      id: nanoid(),
      name: 'Home',
      url: 'hero', // dont include # symbol
    },
    */
    {
      id: nanoid(),
      name: 'About Me',
      url: 'about',
    },
    {
      id: nanoid(),
      name: 'Experience',
      url: 'experience',
    },
    {
      id: nanoid(),
      name: 'Projects',
      url: 'projects',
    },
    {
      id: nanoid(),
      name: 'Contact Me',
      url: 'contact',
    }
  ],
};

// HERO DATA
export const heroData = {
  title: 'Welcome to the Portfolio Website of',
  name: 'Rishi Singh Bhachu',
  subtitle: 'Full Stack Developer 👨🏽‍💻',
  cta: 'more info', // link scrolls too About Me
};

// ABOUT DATA
export const aboutData = {
  img: '',
  img2: '',
  paragraphOne: '<b>Full Stack Developer</b> based in the UK, with over <b>15 year’s experience</b> working with individuals to <b>large multi-national companies</b>.',
  paragraphTwo: 'A <b>self-motivated</b> and reliable <b>team member</b>, experienced with managing high-profile <b>Full Life-Cycle</b> projects using <b>CI/CD</b> with an <b>Agile</b> approach. From <b>concept</b> to <b>deployment</b>, to ongoing <b>development</b>,  <b>maintenance</b> and <b>testing</b>.',
  paragraphThree: '<b>Confident</b> working on all types of <b>projects</b> from <b>Small</b> to <b>Large</b>, <b>Greenfield</b> or <b>Brownfield</b>. Having a strong belief in creating comprehensive, <b>cross-platform</b>, <b>cross-browser</b> code that produces <b>high-quality</b>, <b>functional</b> websites.',
  paragraphFour: 'Liaising directly with <b>Senior management</b> and third parties, to <b>supporting</b>, <b>mentoring staff</b> and <b>junior members</b>.',
  paragraphFive: 'Always ensuring I stay <b>up-to-date</b> with the <b>latest trends</b> and <b>standards</b> within the industry too, via <b>self-study</b> and industry <b>blogs / discord</b>.',
  resume: '', // if no resume, the button will not show up
};


// EXPERIENCE DATA
export const experienceData = [
  { // WEBSITES
    id: nanoid(),
    title: 'Websites 🌐',
    info: "Gallery of websites I have worked on from Canadian Affair to Air Transat. Including Front-End to Back-End, Full Life-Cycle Continuous Integration & Continuous Delivery / Deployment with an Agile Approach.",
    info2: 'HTLM, CSS, JavaScript, JQuery, Soap API, XML, Responsive Layout, Multilingual, Classic ASP, ASP.NET, SQL, Oracle Database, PostCode Anywhere, Google Tag Manager, Adobe CC, CI/CD',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'websites-caf1.png',
    alt: 'Canadian Affair UK Homepage & Flight Search',
    img2: 'websites-caf2.png',
    alt2: 'Canadian Affair UK Holidays, Hotels, Excursions & Car Hire',
    img3: 'websites-ca-ie-aaf.png',
    alt3: 'Canadian Affair Ireland, Canada & American Affair',
    img4: 'websites-affairtravel.png',
    alt4: 'Affair Travel Villas',
    img5: 'websites-transat.png',
    alt5: 'Air Transat UK, Ireland, Italy, Portugal, Spain & Greece',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
  },
  { // WORDPRESS
    id: nanoid(),
    title: 'WordPress',
    info: "Management and Development for the Canadian Affair & American Affair WordPress Blogs. Hosted via WP Engine with Staging for CI/CD.",
    info2: 'WordPress, WP Engine, Staging, Blog, Integrated Blog, PHP, WordPress Plugins, CMS, CI/CD',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'wordpress-caf1.png',
    alt: 'Canadian Affair WordPress Blog',
    img2: 'wordpress-aaf1.png',
    alt2: 'American Affair WordPress Blog',
    img3: 'wordpress-caf2.png',
    alt3: 'WordPress Blogs hosted via WP Engine with Staging',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
  },
  { // HTML EMAILERS
    id: nanoid(),
    title: 'Emailers 📥',
    info: "Gallery of HTML Responsive Emailers including Promotional, BAU, Upsell and Advisory types. Multi-Client / Browser / Device Tested with Domain Deliverability & Reputation Monitoring / Testing.",
    info2: 'Responsive HTML Email, Responsys, MailChimp / Mandrill, Campaign Monitor, MX Toolbox',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'emailers-caf1.png',
    alt: 'Canadian Affair Special Offers',
    img2: 'emailers-caf3.png',
    alt2: 'Canadian Affair Special Offers',
    img3: 'emailers-caf2.png',
    alt3: 'Canadian Affair Count Down Sale',
    img4: 'emailers-caf4.png',
    alt4: 'Canadian Affair Upsell',
    img5: 'emailers-caf5.png',
    alt5: 'Canadian Affair Flight Schedule Change Template for Responsys',
    img6: 'emailers-affair1.png',
    alt6: 'Affair Travel Villas',
    img7: 'emailers-affair2.png',
    alt7: 'Affair Travel Villas',

  },
  { // EMAIL AUTOMATION
    id: nanoid(),
    title: 'Email Automation 🛠️',
    info: "Creation of various automated Programmes within Oracle Responsys Email Automation Platform. Including Welcome Sign-up, BAU, Upsell and Re-Permission Campaigns. Capturing user website activity via Responsys API & GTM.",
    info2: 'Oracle Responsys, RPL, Google Tag Manager, GA, Email Automation, Email Programmes / Campaigns, Responsive HTML Email Templates, Data Capture, Triggered Events',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'experience-responsys3.png',
    alt: 'Responsys Welcome Sign-up Automated Program',
    img2: 'experience-responsys1.png',
    alt2: 'Responsys Abandoned Cart Automated Program',
    img3: 'experience-responsys2.png',
    alt3: 'Responsys Upsell Automated Program',
    img4: 'experience-responsys4.png',
    alt4: 'Responsys Re-Permission / GDPR Program',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
  },
  { // RICHIBILLIONS
    id: nanoid(),
    title: 'Richie Billions',
    info: "Richie Billions was a Reflective Streetwear clothing start-up I created solely in 2018. Consisting of unique T-Shirt designs which `illuminate` when LED lighting is present (camera / phone flash or in a Nightclub).<br>The website was hosted on the Shopify platform using a custom theme I had created based on the native Shopify Liquid (Ruby based) coding language + CSS / SASS.<br>I also created the promotional media for the website and social media platforms with deep links. Including a promotional video created just using my Samsung S7 Edge Phone, with the final edit composed and completed with Adobe Premiere & Audition.",
    info2: 'Shopify (CMS, Liquid / Ruby, SASS), YouTube, Facebook, Instagram, Marketing, SEO, Photography, Videography (Greenscreen / Chroma Key, Editing) DJ / Audio, Adobe CC, Merchandising, Garment Production, Packaging',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'projects-rb-video.png',
    alt: 'Richie Billions Promotional Video',
    img2: 'projects-rb-promo1.jpg',
    alt2: 'Website, Social Media Promo / Campaigns',
    img3: 'projects-rb-promo2.jpg',
    alt3: 'Website, Social Media Promo / Campaigns',
    img4: 'projects-rb-web-front.jpg',
    alt4: 'Website Frontend - Responsive, Mobile Optimised',
    img5: 'projects-rb-web-back.jpg',
    alt5: 'Website Backend - Shopify CMS, Liquid (Ruby) + SASS Code Template Editing',
    img6: 'projects-rb-behind.jpg',
    alt6: 'Behind The Scenes',
    img7: '',
    alt7: '',
  }
];





// PROJECTS DATA
export const projectsData = [
  { // MOVIESEARCH
    id: nanoid(),
    title: 'Movie Database 🎞️',
    info: "React Movie Search, created with React (Hooks / Effect), HTML, FlexBox, SASS, FontAwesome. Connecting to The Movie DB via a RESTful API, converted to JSON Format.",
    info2: 'HTML5, CSS3, CSS FlexBox, SASS, React (Hooks / Effect), Node.js, Express, The Movie DB API, RESTful API, Custom Fav Icon, SPA, PWA, GitHub > Netlify CD',
    url: 'https://rbhachu-react-movie-search.netlify.app', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/movie-search', // if no repo, the button will not show up
    img: 'projects-movie.png',
    alt: 'Movie Search App with Responsive Layout',
    img2: 'projects-movie2.png',
    alt2: 'Pop-out Responsive Menu featuring `Top Searches` Deep Links',
    img3: 'projects-movie3.png',
    alt3: 'Resposive Animated Flip Cards, displaying Full Movie Titles & Descriptions',
    img4: 'projects-movie4.png',
    alt4: 'Custom User-Friendly Error Messages',
    img5: 'projects-movie5.png',
    alt5: 'Resposive Mobile Layout with Fixed (portrait) & Un-Fixed Header (landscape)',
    img6: 'projects-movie6.png',
    alt6: 'Github Repo to Netlify Hosting - Continuous Deployment (CD)',
    img7: '',
    alt7: '',
  },
  { // SMARTBRAIN
    id: nanoid(),
    title: 'Smart Brain 🧠',
    info: 'A React App that detects, then highlights faces in web images. Including a user profile with image upload count & Ranking Badge served from AWS Lambda as a serverless function.<br><i>(Test Login Email: a@a.com / Pass: a)</i>',
    info2: 'HTML5, CSS3, FlexBox, Tachyon CSS, React, React Hooks, Node.js, Express, Clarifai Face Detection API, RESTful API, Custom Fav Icon, Redis, JSON Web Tokens, bcrypt, AWS Lambda, PostgreSQL, SPA, PWA, Docker, GitHub > Heroku CD',
    url: 'https://rbhachu-smartbrain-f-master.herokuapp.com', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/smartbrain-frontend-jwt', // if no repo, the button will not show up
    img: 'projects-smartbrain.png',
    alt: 'Smart Brain App with Responsive Layout',
    img2: 'projects-smartbrain2.png',
    alt2: 'User Profile with Rank, Total Images Uploaded, Date Joined and Edit Name Feature',
    img3: 'projects-smartbrain3.png',
    alt3: 'Sign-in & Register Form Validation',
    img4: 'projects-smartbrain4.png',
    alt4: 'Rank Badge served from external AWS Lambda Serverless',
    img5: 'projects-smartbrain5.png',
    alt5: 'User Profile Authentication / Session / Encryption - PostgreSQL, Redis, JSON Web Tokens, bcrypt',
    img6: 'projects-smartbrain6.png',
    alt6: 'Logo Hover Tilt Effect, Background Particles Effect, Custom Fav Ico, Tabbed Interface, Multi-Face Detection, PWA Enabled',
    img7: 'projects-smartbrain7.png',
    alt7: 'Github Repo to Heroku Hosting - Continuous Deployment (CD)',
  },
  { // ROBOFRIENDS
    id: nanoid(),
    title: 'RoboFriends 🤖',
    info: 'A responsive single page web application, allowing users to filter the Robots results data via the search form. Created with React App, connecting to a mock API from JSON Placeholder via a Fetch statement.',
    info2: 'HTML5, CSS3, Tachyon CSS, React, React Hooks, React Redux, Node.js, Express, RESTful API, Enzyme Testing, CI, Custom Fav Icon, SPA, PWA, GitHub > Netlify CD',
    url: 'https://rbhachu-robofriends.netlify.app', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/robofriends', // if no repo, the button will not show up
    img: 'projects-robofriends.png',
    alt: 'RoboFriends App with Responsive Layout',
    img2: 'projects-robofriends2.png',
    alt2: 'Real-Time Results Filter',
    img3: 'projects-robofriends3.png',
    alt3: 'Github Repo to Netlify Hosting - Continuous Deployment (CD)',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
  },
  { // MOJOBLOG
    id: nanoid(),
    title: 'The Mojo Blog 📓',
    info: "A simple React.js Blog using custom hooks, with a RESTful API connecting to a JSON Server. Plus pagination and redirects from React Router Dom.",
    info2: 'HTML5, CSS3, Tachyon CSS, React (Hooks / Effect), React Router, Node.js, Express, Custom Error 404 Page, RESTful API, JSON Server, SPA, PWA, GitHub > Heroku CD',
    url: 'https://rbhachu-the-mojo-blog.herokuapp.com', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/the-mojo-blog', // if no repo, the button will not show up
    img: 'projects-themojoblog.png',
    alt: 'Mojo Blog App with Responsive Layout',
    img2: 'projects-themojoblog2.png',
    alt2: 'Create New Blog Post & Select Blog Author',
    img3: 'projects-themojoblog3.png',
    alt3: 'View and Delete Blog Posts via dedicated post urls',
    img4: 'projects-themojoblog4.png',
    alt4: 'View All Blog Posts',
    img5: 'projects-themojoblog5.png',
    alt5: 'Custom Error 404 Page',
    img6: 'projects-themojoblog6.png',
    alt6: 'Github Repo to Heroku Hosting - Continuous Deployment (CD)',
    img7: '',
    alt7: '',
  },
  { // MAMEARCADE
    id: nanoid(),
    title: 'MAME Arcade Machine 🕹️',
    info: 'A fun project that I completed previously in my spare time was refurbishing my classic retro MAME Arcade Machine.<br>From replacing the internal PC to a higher spec, the CRT screen to a more modern LCD flat panel, to adding brand new illuminated controls with a new layout schema, plus much more.<br>The Games ROM Library was also updated, running LaunchBox to support over 6500 retro games, plus emulated console support (SNES, MegaDrive, PS etc). Including such classics as Street Fighter, Double Dragon, Dragon Ninja & Golden Axe.<br>Plus the addition of more modern games like Ultra Street Fighter IV with online play support via a Steam plugin.',
    info2: 'B&Q, ArcadeWorldUK, Patience & Perseverance, Electronics, Multi-meter, DIY Toolbox, MAME Arcade Roms, Sanwa Joysticks / Buttons, iPac Controller, LED Lighting, LaunchBox Emulation Software, STEAM Plug-In, Online Play, Super BASS, Wi-Fi',
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'bhachucade3000.jpg',
    alt: 'BhachuCade 3000 - Retro Mame Arcade Machine',
    img2: 'bhachucade-completed.jpg',
    alt2: 'Mame Arcade Machine Completed Build',
    img3: '',
    alt3: '',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
  }
];

// CONTACT DATA
export const contactData = {
  img: 'avataaars-lookup.png',
  // cta: 'To <b>contact me</b> please drop me a message via <b>Email</b> or <b>LinkedIn</b>',
  cta: 'To contact me please use the form below, and I will aim to get back to you promplty, thanks!',
  cta2: 'Thank you, your message has been submitted successfully, and I will aim to get back to you shortly.',
  cta3: 'Error Sending Message!<br>Please contact me via email: <u>mr_bhachu@hotmail.com</u>',
  //cta3: '<span className="alert">Error Sending Message!<br>Please contact me via email: <a target="_blank" rel="noopener noreferrer" class="contact-button" href="mailto:mr_bhachu@hotmail.com">mr_bhachu@hotmail.com</a></span>',
  btn: 'Go to LinkedIn',                                                               
  email: 'mr_bhachu@hotmail.com',
};

// FOOTER DATA
// Icons list Reference: https://mdbootstrap.com/docs/react/content/icons-list/
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rbhachu',
    },
    {
      id: nanoid(),
      name: 'stack-overflow',
      url: 'https://stackoverflow.com/users/5238978/juggy',
    }, 
    {
      id: nanoid(),
      name: 'hashtag',
      url: 'https://hashnode.com/@bhachublog',
    },  
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/rishibhachu/',
    },
  ],
};